<template>
  <v-container
      id="panels"
      fluid
      tag="section"
  >
    <v-row no-gutters>
      <v-col cols="12" md="12">

        <base-material-card color="success" icon="mdi-card-account-details" title="My Account">
          <v-row no-gutters>
            <v-col cols="12" md="12">

              <template v-if="!user">
                <v-progress-linear
                    indeterminate
                    color="primary"
                ></v-progress-linear>
                <v-skeleton-loader loading class="mx-auto" type="card"></v-skeleton-loader>
              </template>
              <template v-else>
                <v-tabs right show-arrows>
                  <v-tab>
                    <v-icon class="mr-1">mdi-account</v-icon>
                    Profile
                  </v-tab>
                  <v-tab>
                    <v-icon class="mr-1">mdi-lock</v-icon>
                    Password
                  </v-tab>
                  <v-tab>
                    <v-icon class="mr-1">mdi-mail</v-icon>
                    Email
                  </v-tab>

                  <v-tab>
                    <v-icon class="mr-1">mdi-two-factor-authentication</v-icon>
                    MFA
                  </v-tab>

                  <v-tab class="error--text">
                    <v-icon class="mr-1">mdi-delete</v-icon>
                    Delete
                  </v-tab>

                  <v-tab-item class="mt-2">
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                            label="First Name"
                            v-model="user.firstName"
                            prepend-icon="mdi-account"
                        />
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-text-field
                            label="Last Name"
                            v-model="user.lastName"
                            prepend-icon="mdi-account"
                        />
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-text-field
                            label="Username"
                            v-model="user.username"
                            prepend-icon="mdi-account-details"
                        />
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-text-field
                            label="email"
                            type="email"
                            v-model="user.email"
                            prepend-icon="mdi-mail"
                            disabled
                        />
                      </v-col>

                      <v-col cols="12">
                        <label class="v-label v-label--active theme--light">Profile</label>
                        <v-jsoneditor
                            class="py-2"
                            v-model="user.profile" :plus="true" :options="{mode: 'code'}" height="400px"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="text-right">
                        <v-spacer></v-spacer>
                        <v-btn color="success" class="text-right" small @click="checkProfileForm">
                          <v-icon small class="mr-1">mdi-content-save</v-icon>
                          Change
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item class="mt-2">
                    <v-row no-gutters>
                      <v-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                        <v-col cols="12" md="12">
                          <v-text-field
                              label="Current password"
                              type="password"
                              prepend-icon="mdi-lock"
                              v-model="oldPassword"
                              hint="Enter your current password"
                          />
                        </v-col>

                        <v-col cols="12" md="12">
                          <v-text-field
                              label="Password"
                              type="password"
                              prepend-icon="mdi-lock"
                              v-model="password"
                              hint="Enter a new password"
                          />
                        </v-col>

                        <v-col cols="12" md="12">
                          <v-text-field
                              label="Confirm Password"
                              type="password"
                              prepend-icon="mdi-lock"
                              v-model="passwordConfirmation"
                              hint="Confirm the new password"
                          />
                        </v-col>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                        <v-alert
                            class="pa-1 pl-5"
                            type="info"
                            outlined
                            border="left">
                          The Password requires at least one digit.
                        </v-alert>
                        <v-alert
                            class="pa-1 pl-5"
                            type="info"
                            outlined
                            border="left">
                          The Password requires at least one lowercase letter.
                        </v-alert>
                        <v-alert
                            class="pa-1 pl-5"
                            type="info"
                            outlined
                            border="left">
                          The Password requires at least one uppercase letter.
                        </v-alert>
                        <v-alert
                            class="pa-1 pl-5"
                            type="info"
                            outlined
                            border="left">
                          The Password requires at least one special character.
                        </v-alert>
                        <v-alert
                            class="pa-1 pl-5"
                            type="info"
                            outlined
                            border="left">
                          The Password should be between 10 and 32 characters.
                        </v-alert>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="text-right">
                        <v-spacer></v-spacer>
                        <v-btn color="success" class="text-right" small @click="checkPasswordForm">
                          <v-icon small class="mr-1">mdi-content-save</v-icon>
                          Update
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item class="mt-2">
                    <v-row>
                      <v-col cols="12" md="12">
                        <v-text-field
                            label="Email"
                            type="email"
                            v-model="email"
                            hint="Once you click update, a link will be sent to the new email you provided. Click on the link to complete the process."
                            prepend-icon="mdi-email"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="text-right">
                        <v-spacer></v-spacer>
                        <v-btn color="success" class="text-right" small @click="checkEmailForm">
                          <v-icon small class="mr-1">mdi-content-save</v-icon>
                          Change
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item class="mt-2">
                    <v-row>
                      <v-col cols="12" md="12">
                        <div style="margin-left: 10px">
                          <v-select
                              label="MFA Security"
                              v-model="myMfa"
                              required
                              item-text="text"
                              item-value="value"
                              :items="mfaSettings"
                              hint="Specify whether or not to turn on Multi Factor Authentication."
                              persistent-hint
                          ></v-select>
                        </div>

                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="text-right">
                        <v-spacer></v-spacer>
                        <v-btn color="success" class="text-right" small
                               :disabled="originalMfa===myMfa" @click="switchMFA">
                          <v-icon small class="mr-1">mdi-content-save</v-icon>
                          Change
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item class="mt-2">
                    <v-row>
                      <v-col cols="12" md="12">
                        <br>
                        <h3 class="error--text">Delete Account </h3>
                        <v-divider class="mt-3"/>
                        <p>You will loose access to your CorsairM360 account once your deletion request has been
                          submitted.</p>
                        <p>Your organization account and all the users under it will be deleted as well.</p>
                        <p>If you decide to access your account again or retrieve any data from it, you will not be able
                          to, and the support at CorsairM360 will not be able to assist you either.</p>
                        <p class="red--text font-weight-bold">This step is irreversible.</p>
                      </v-col>
                    </v-row>
                    <v-divider class="mb-4"/>
                    <v-row>
                      <v-col cols="12" class="text-center">
                        <p class="text-muted">Are you sure you wan to delete you account?</p>
                        <v-btn color="error" class="" medium @click="deleteAccount">
                          <v-icon small class="mr-1">mdi-delete</v-icon>
                          Delete
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                </v-tabs>
              </template>
            </v-col>
          </v-row>
        </base-material-card>

        <v-dialog
            v-model="checkDialog"
            width="70%"
        >
          <v-card>
            <v-card-title class="text-h2 white--text pb-3" :style="{'background-color': 'red'}">
              Delete My Account
            </v-card-title>

            <v-card-text class="text-left">
              <Messages :messages="errorMessages"></Messages>

              <p class="my-4">
                We hate to see you go, but we understand and wish you great fortune.</p>

              <template>
                <p>
                  To finalize the account deletion process, please type in your
                  <span class="red--text font-weight-bold">username</span> in the box below and agree to the Terms of
                  Service.
                  <v-text-field
                      label="Username"
                      v-model="myusername"
                      prepend-icon="mdi-account-details"
                  />
                </p>

                <v-checkbox v-model="agreeTerms">
                  <template v-slot:label>
                    <div>
                      I have read and I agree to the
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <a target="_blank"
                             href="https://www.m360api.io/terms-of-service/"
                             @click.stop
                             v-on="on">Terms of Service</a>
                        </template>
                        Terms of Service
                      </v-tooltip>
                      and to the
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <a target="_blank"
                             href="https://www.m360api.io/privacy-policy"
                             @click.stop
                             v-on="on">Privacy Policy</a>
                        </template>
                        Privacy Policy
                      </v-tooltip>
                      .
                    </div>
                  </template>
                </v-checkbox>

                <h4>Can you spare a minute and tell us why you wish to delete your account ?</h4>
                <v-textarea solo v-model="reason"/>

              </template>

            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn @click="cancelDelete">
                <v-icon class="mr-2">mdi-cancel</v-icon>
                Cancel
              </v-btn>

              <v-spacer></v-spacer>

              <v-btn
                  color="error"
                  @click="doDeleteAccount"
              >
                <v-icon class="mr-2">mdi-delete</v-icon>
                Delete
              </v-btn>

            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import globalMixins from "../../../../mixins/globalMixins";
import Messages from "../../../../components/Messages";
import VJsoneditor from "v-jsoneditor";

const data = {
  myMfa: 'default',
  originalMfa: '',
  errorMessages: [],
  checkDialog: false,
  user: null,
  agreeTerms: false,
  myusername: '',
  oldPassword: '',
  password: '',
  passwordConfirmation: '',
  email: '',
  reason: '',
  mfa: {
    enabled: false
  }
};

const methods = {

  async getUser() {
    this.user = JSON.parse(JSON.stringify(this.$store.getters['auth/getUser']));

    this.getSendData({
      'url': `/user`,
      'method': 'get'
    }).then((user) => {
      this.user = user.user;

      if (this.user.security && this.user.security.hasOwnProperty('mfa')) {
        this.myMfa = this.user.security.mfa;
      }
      // clone
      this.originalMfa = this._lodash.clone(this.myMfa);
    }).catch(() => {
      this.$store.dispatch('auth/clear');
      this.$cookies.remove('token');
      this.clearMessages();
      if (this.$router.currentRoute.name !== 'Login') {
        this.$router.push({name: "Login"});
      }
    });
  },

  checkPasswordForm: function (e) {
    if (!this.oldPassword) {
      this.pushMessage({
        'type': 'error',
        'title': 'Validation Error',
        'text': 'Please enter your current password.'
      });
      return false;
    }

    if (!this.validPassword(this.password)) {
      this.pushMessage({
        'type': 'error',
        'title': 'Validation Error',
        'text': 'The password should be at least 10 characters long and contain 1 uppercase letter, 1 lowercase letter, 1 special character and 1 digit.'
      });
      return false;
    }

    if (this.password !== this.passwordConfirmation) {
      this.pushMessage({
        'type': 'error',
        'title': 'Validation Error',
        'text': 'The provided password and confirmation don\'t match.'
      });
      return false;
    }

    this.submitPasswordChange();
    e.preventDefault();
  },

  submitPasswordChange() {
    const self = this;
    self.getSendData({
      'url': '/user/password/change',
      'method': 'post',
      'headers': {
        'env': 'portal'
      },
      'params': {
        'old': self.oldPassword,
        'password': self.password,
        'confirmation': self.passwordConfirmation,
      }
    }).then(() => {
      this.pushMessage({
        type: 'success',
        title: 'Password Changed',
        text: 'You have successfully changed your password.'
      });
      //this.cancelPasswordForm();
    }).catch(() => {
      // already handled in getSendData
    });
  },

  checkEmailForm: function (e) {
    if (!this.validEmail(this.email)) {
      this.pushMessage({
        'type': 'error',
        'title': 'Validation Error',
        'text': 'Please enter a valid email address.'
      });
      return false;
    }

    this.submitEmailChange();
    e.preventDefault();
  },

  submitEmailChange() {
    const self = this;
    self.getSendData({
      'url': '/user/email/change',
      'method': 'post',
      'headers': {
        'env': 'portal'
      },
      'params': {
        "auto_register_flow": "corsair_saas",
        'email': self.email,
      }
    }).then(() => {
      this.pushMessage({
        type: 'success',
        title: 'Verification Email Sent',
        text: 'Please check your email inbox to verify the new email address.'
      });
      //this.cancelEmailForm();
    }).catch(() => {
      // already handled in getSendData
    });
  },

  checkProfileForm(e) {
    if (!this.user.firstName || this.user.firstName.trim() === '') {
      this.pushMessage({
        'type': 'error',
        'title': 'Validation Error',
        'text': 'Please enter your first name!'
      });
      return false;
    }

    if (!this.user.lastName || this.user.lastName.trim() === '') {
      this.pushMessage({
        'type': 'error',
        'title': 'Validation Error',
        'text': 'Please enter your last name!'
      });
      return false;
    }

    if (!this.user.username || this.user.username.trim() === '') {
      this.pushMessage({
        'type': 'error',
        'title': 'Validation Error',
        'text': 'Please enter a username!'
      });
      return false;
    }

    this.submitProfileForm();
    e.preventDefault();
  },

  submitProfileForm() {
    const self = this;
    self.getSendData({
      'url': '/user',
      'method': 'put',
      'headers': {
        'env': 'portal'
      },
      'params': {
        'username': this.user.username,
        'firstName': this.user.firstName,
        'lastName': this.user.lastName,
        'profile': this.user.profile
      }
    }).then(() => {
      this.pushMessage({
        type: 'success',
        title: 'Profile Updated',
        text: 'Your profile information has been updated.'
      });
      this.getSendData({
        'url': '/user',
        'method': 'get'
      }).then(response => {
        this.$store.dispatch('auth/setUser', response);
      });
      setTimeout(() => {
        this.user = JSON.parse(JSON.stringify(this.$store.getters['auth/getUser']));
        // this.getUser();
      }, 2000);
    }).catch(() => {
      // already handled in getSendData
    });
  },

  deleteAccount() {
    this.checkDialog = true;
  },

  cancelDelete() {
    this.errorMessages = [];
    this.checkDialog = false;
  },

  switchMFA() {
    const self = this;
    self.getSendData({
      "method": "patch",
      "url": "/user/security",
      'headers': {
        'env': 'portal'
      },
      'params': {
        "username": self.user.username,
        "mfa": self.myMfa
      }
    }).then(() => {
      self.pushMessage({
        type: 'success',
        title: 'MFA Updated',
        text: 'Your profile information has been updated.'
      });

      setTimeout(() => {
        self.getSendData({
          'url': '/user',
          'method': 'get'
        }).then(response => {
          self.$store.dispatch('auth/setUser', response);
        });
      }, 4000);
    });
  },

  doDeleteAccount() {
    const self = this;
    this.errorMessages = [];
    this.showLoading = true;

    if (!this.myusername) {
      this.errorMessages.push({
        'type': 'error',
        'title': 'Validation Error',
        'text': 'Please enter your username.'
      });
      return false;
    }

    if (this.myusername !== this.user.username) {
      this.errorMessages.push({
        'type': 'error',
        'title': 'Validation Error',
        'text': 'Invalid username.'
      });
      return false;
    }

    if (!this.agreeTerms) {
      this.errorMessages.push({
        'type': 'error',
        'title': 'Validation Error',
        'text': 'Please check the Terms of service and privacy policy.'
      });
      return false;
    }

    this.checkDialog = false;
    this.showLoading = true;
    self.getSendData({
      'url': '/saas/delete/account',
      'method': 'post',
      'headers': {
        'env': 'portal'
      },
      'params': {
        "reason": this.reason
      }
    }).then(() => {
      this.pushMessage({
        type: 'success',
        title: 'Account Deleted',
        text: 'Account Deleted'
      });

      setTimeout(() => {
        this.refreshAfterDelete();
      }, 2000);
    }).catch(() => {
      // already handled in getSendData
    });
  },

  refreshAfterDelete() {
    this.$store.dispatch('auth/clear');
    this.$cookies.remove('token');
    this.clearMessages();

    if (this.$router.currentRoute.name !== 'Login') {
      this.$router.push({name: "Login"});
    }
  },
};

export default {
  name: 'Profile',

  mixins: [globalMixins],

  components: {
    VJsoneditor, Messages
  },

  data: () => (data),

  async created() {
    this.getUser();
  },

  methods: methods
}
</script>

<style scoped>
.routerLink {
  text-decoration: none;
}

</style>
